import React from 'react'

const Cta = () => {
  return (
    <div className='row justify-content-center text-center'>
      <div className='col-8'>
        <div className='card shadow border-primary text-primary my-3'>
          <div className='card-body'>
            <div className='card-title'>
              <h2>
                If you want a quotation or would like to know more please give
                us a call on{' '}
                <strong>
                  <a href='tel:03451223019'>0345 450 3359</a>
                </strong>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cta
