import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Article from '../components/article'
import Cta from '../components/cta'
import { StaticImage } from 'gatsby-plugin-image'

const CombinedHomeInsurance = () => {
  return (
    <>
      <Seo
        pageTitle={'Combined Home Insurance'}
        description={`If you own your home you know the importance of having the right cover for your home and its contents. With our Residential Home Insurance policy we can provide valuable cover for both your home and your treasured possessions.`}
      />
      <Layout>
        <main>
          <Article>
            <div className='card shadow border-primary mb-3 p-2'>
              <StaticImage
                className='card-img-top'
                src={'../images/combined.png'}
                alt={'Combined Home Insurance'}
                placeholder='blurred'
                Layout={'fullWidth'}
                formats={['auto', 'webp', 'avif']}
                quality={100}
              ></StaticImage>
            </div>

            <h1 className='display-4'>Combined Home Insurance</h1>
            <p>
              If you own your home you know the importance of having the right
              cover for your home and its contents. With our Residential Home
              Insurance policy we can provide valuable cover for both your home
              and your treasured possessions.
            </p>

            <section className='my-3'>
              <h2 className='display-6'>Buildings Insurance</h2>
              <p>
                We can automatically provide buildings cover up to £1,000,000
                for the cost of rebuilding your home and any outbuildings.
              </p>
              <p>On top of that, our buildings insurance also:</p>
              <ul className='list-group list-group'>
                <li className='list-group-item'>
                  Covers the structure of your home, roofs, walls, fences, gates
                  and outbuildings, plus permanent fixtures like kitchens,
                  bathrooms and fitted wardrobes,
                </li>
                <li className='list-group-item'>
                  Covers fire, lightning, storm or flood (excluding fences and
                  gates), theft, explosion, escape of water or oil, vandalism
                  and subsidence
                </li>
                <li className='list-group-item'>
                  Covers burst pipes and the cost of alternative accommodation
                  if necessary (following an insured event)
                </li>
                <li className='list-group-item'>
                  Covers accidental breakage as standard for underground pipes,
                  drains and cables, plus fixed glass in windows and doors and
                  sanitary fixtures and fittings.
                </li>
                <li className='list-group-item'>
                  Gives you the option to include extended accidental damage for
                  incidents such as DIY accidents and leaving taps running.
                </li>
                <li className='list-group-item'>
                  Gives you the option to include access to a 24-hour Emergency
                  Helpline
                </li>
                <li className='list-group-item'>
                  Gives you the option to include £50,000 of Personal Legal
                  Protection
                </li>
              </ul>
            </section>

            <section className='my-3'>
              <h2 className='display-6'>Contents Insurance</h2>
              <p>
                We can automatically provide you with contents cover of £60,000
                as standard.
              </p>
              <p>On top of that, our contents insurance also includes:</p>
              <ul className='list-group list-group-flush'>
                <li className='list-group-item'>
                  Provide cover on a new-for-old basis for household goods,
                  including furniture, carpets, curtains, and your personal
                  belongings in the home, garages and sheds
                </li>
                <li className='list-group-item'>
                  Provide automatic cover for accidental breakage to fixed glass
                  in furniture, television sets, satellite receivers, radios,
                  DVD players, desk-top personal computers and games consoles
                  capable of playing DVDs.
                </li>
                <li className='list-group-item'>
                  Gives you the option to include extended accidental damage
                  cover for incidents such as spillages on carpets or damage to
                  furniture or laptops.
                </li>
                <li className='list-group-item'>
                  Cover cash in your home up to £750, plus items in the garden
                  up to £750
                </li>
                <li className='list-group-item'>
                  Cover against fire, smoke damage, lightning, theft, flood and
                  escape of water or oil
                </li>
                <li className='list-group-item'>
                  Cover freezer contents, replacement locks and keys following
                  theft of keys, and possessions temporarily removed from the
                  home to another e.g. while children are at college (up to
                  £5,000)
                </li>
                <li className='list-group-item'>
                  Include an automatic increase of 10% of the sums insured for
                  several weeks either side of Christmas and family weddings
                </li>
                <li className='list-group-item'>
                  Gives you the option to include cover away from the home and
                  worldwide cover for your personal possessions.
                </li>
                <li className='list-group-item'>
                  Gives you the option to include £50,000 of Personal Legal
                  Protection
                </li>
              </ul>
            </section>
            <Cta />
          </Article>
        </main>
      </Layout>
    </>
  )
}

export default CombinedHomeInsurance
